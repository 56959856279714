import { CustomOverlayMap } from "react-kakao-maps-sdk";
import './MapAreas.css';
import { locationMatch } from "../../../../common/func/match";
import { useNavigate } from "react-router-dom";

export default function MapAreas (props) {
	const navigate = useNavigate();
  const currentArea = props.recommendedArea[props.step];
  return (
    <>
      { props.recommendedArea?.map((area, index) => {
        return (
          <CustomOverlayMap
            position={{
              lat: area.latitude,
              lng: area.longitude,
            }}
            key={index}
          >
            <div className="areaMarker" onClick={() => navigate(`/locations/${area.locationId}`)} style={area === currentArea ? {border: "2px solid var(--Gray1, #000)", boxShadow: "0px 10px 24px 0px rgba(0, 0, 0, 0.25)"} : null}>
              { area === currentArea ? 
                <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                  <path d="M30.48 9.52322C30.2544 9.32653 29.9754 9.20132 29.6785 9.16348C29.3817 9.12565 29.0802 9.1769 28.8125 9.31072L22.5362 12.4357L17.2962 3.73197C17.1615 3.50863 16.9714 3.32388 16.7443 3.19564C16.5172 3.06739 16.2608 3 16 3C15.7392 3 15.4828 3.06739 15.2557 3.19564C15.0286 3.32388 14.8384 3.50863 14.7037 3.73197L9.46375 12.4395L3.19 9.31447C2.92292 9.18184 2.62262 9.13103 2.32678 9.16844C2.03094 9.20585 1.75274 9.3298 1.52709 9.52474C1.30143 9.71969 1.13839 9.97693 1.0584 10.2642C0.978416 10.5515 0.985058 10.856 1.07749 11.1395L5.70249 25.3095C5.749 25.4518 5.82688 25.5819 5.93039 25.6901C6.0339 25.7984 6.16039 25.882 6.30054 25.9348C6.44068 25.9876 6.59089 26.0082 6.74008 25.9952C6.88927 25.9821 7.03363 25.9358 7.16249 25.8595C7.19374 25.8407 10.39 23.9995 16 23.9995C21.61 23.9995 24.8062 25.8407 24.835 25.8582C24.9639 25.9352 25.1085 25.9823 25.2581 25.9957C25.4076 26.0092 25.5583 25.9889 25.6989 25.9361C25.8396 25.8834 25.9665 25.7997 26.0703 25.6912C26.1742 25.5827 26.2522 25.4523 26.2987 25.3095L30.9237 11.1432C31.0188 10.8596 31.0274 10.5542 30.9484 10.2657C30.8694 9.97722 30.7063 9.71876 30.48 9.52322ZM21.98 19.5995C21.9392 19.8308 21.8182 20.0404 21.6384 20.1915C21.4585 20.3425 21.2311 20.4255 20.9962 20.4257C20.9376 20.4256 20.8791 20.4206 20.8212 20.4107C17.6271 19.8624 14.3629 19.8624 11.1687 20.4107C11.0394 20.4335 10.9068 20.4307 10.7786 20.4022C10.6503 20.3738 10.529 20.3204 10.4214 20.2451C10.204 20.0929 10.0561 19.8607 10.01 19.5995C9.96391 19.3382 10.0235 19.0694 10.1756 18.8521C10.3278 18.6348 10.56 18.4868 10.8212 18.4407C14.2453 17.8524 17.7447 17.8524 21.1687 18.4407C21.2986 18.4629 21.4227 18.5106 21.5341 18.5809C21.6454 18.6512 21.7418 18.7428 21.8176 18.8505C21.8935 18.9582 21.9473 19.0798 21.976 19.2083C22.0047 19.3368 22.0078 19.4698 21.985 19.5995H21.98Z" fill="black"/>
                </svg> : null
              }
              <img alt={area.locationName} src={area.thumbnail}></img>
              <span className="locationName">{locationMatch[area.locationName]}</span>
            </div>
          </CustomOverlayMap>
        )
      })}
    </>
  );
};